/** @format */

.video-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.video-player {
  display: inline-block;
  width: 960;
  height: 720;
  border: solid 1px #ccc;
  border-radius: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.cmd-container {
  display: flex;
  justify-content: center;
  margin: 2rem auto;
}
.btn-cmd {
  margin: 0.75rem;
  background-color: gray !important;
  border: solid 2px white;
}
.btn-cmd:hover {
  background-color: red !important; /* Green */
  color: white;
}
